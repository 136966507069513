export default {
    handleErrosDoenca(errorData, errorForm) {
        let error = errorData.errors;

        errorForm.nomeTecnico = error.nome_tecnico ? error.nome_tecnico[0] : '';
        errorForm.vacinasSelecionadas = error.ids_vacinas ? error.ids_vacinas[0] : '';
        errorForm.nomePopular = error.nome_popular ? error.nome_popular[0] : '';
        errorForm.descricao = error.descricao ? error.descricao[0] : '';
        errorForm.error = '';

        if(typeof error == 'string') {
            errorForm.error = error;
        }
    }
}